import github from "../assets/github.svg";
import linkedin from "../assets/linkedin.svg";
import twitter from "../assets/twitter.svg";
export const socialLinks = [
  {
    icon: github,
    title: "Github",
    link: "https://github.com/MichaelBoyo",
  },
  {
    icon: linkedin,
    title: "LinkedIn",
    link: "https://linkedin.com/in/michaelboyo",
  },
  {
    icon: twitter,
    title: "Twitter",
    link: "https://x.com/themichaelboyo",
  },
];
