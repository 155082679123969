// mui
import { Tooltip } from "@mui/material";

// react
import { FC } from "react";

interface SocialMediaIconProps {
  imgSrc: string;
  title: string;
  url?: string;
}

const SocialMediaIcon: FC<SocialMediaIconProps> = ({ imgSrc, title, url }) => {
  return (
    <Tooltip title={title} placement="bottom" arrow>
      <a
        href={url}
        className="flex items-center justify-center h-12 w-12 rounded-full bg-icons cursor-pointer
        hover:bg-secondary transition-all ease-linear duration-300"
      >
        <img src={imgSrc} alt={title} className="max-h-6 max-w-6" />
      </a>
    </Tooltip>
  );
};

export default SocialMediaIcon;
