// assets
import homePageImg from "../assets/home-page.svg";
import homePageIllustation from "../assets/hero-illustration.svg";
import downloadIcon from "../assets/download-btn-icon.svg";

// components
import { Button } from "../components";

// react-simple-typewriter
import { Typewriter } from "react-simple-typewriter";

// framer-motion
import { motion } from "framer-motion";

// utils
import { transition } from "../utils/transition";
import { fadeIn, scale } from "../utils/variants";
import { Link } from "react-scroll";
import { stacks } from "../constants/stacks";
import { socialLinks } from "../constants/social.links";

const Hero = () => {
  const handleDownload = () => {
    // select a file from public folder
    window.open(
      "https://drive.google.com/file/d/1PiNHmk6Pg9u2u1nxIlRjF12QMo845eAJ/view?usp=share_link",
      "_blank"
    );
  };
  return (
    <div
      id="home"
      className="min-h-screen flex items-center justify-center relative"
      style={{
        background: `url(${homePageImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <motion.div className="absolute flex gap-4 top-0 right-10 m-4">
        {socialLinks.map((link, index) => (
          <motion.a
            variants={fadeIn("up")}
            transition={transition()}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            key={index}
            href={link.link}
            target="_blank"
            rel="noreferrer"
            className=" "
          >
            <img src={link.icon} alt="" className="w-8 h-8" />
          </motion.a>
        ))}
      </motion.div>
      <div
        className="max-w-screen-2xl flex flex-col 
          xl:flex-row xl:justify-between items-center xl:items-start gap-12 w-full py-16 px-12"
      >
        <div className="w-full xl:w-fit">
          <h1 className="text-secondary font-[700] w-max  xl:text-6xl  text-center text-4xl mb-10">
            MICHAEL BOYO
          </h1>
          <motion.h1
            variants={fadeIn("down")}
            transition={transition()}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            className="w-full xl:w-fit text-center xl:text-start text-4xl sm:text-6xl lg:text-5xl
              font-bolt text-textPrimary "
          >
            Creative
            <br />
            <span className="text-secondary">
              <Typewriter
                words={["Backend Developer", "Frontend Developer"]}
                cursor
                cursorStyle="_"
                typeSpeed={250}
                deleteSpeed={150}
                loop
              />
            </span>
          </motion.h1>
          <motion.div
            variants={fadeIn("up")}
            transition={transition()}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            className="my-12 flex flex-col sm:flex-row items-center gap-6 justify-center xl:justify-start"
          >
            <Link to="contact" smooth>
              <Button secondary>Hire me</Button>
            </Link>
            <Button onClick={handleDownload} icon={downloadIcon}>
              Download CV
            </Button>
          </motion.div>

          <motion.div className=" flex justify-between w-max">
            {stacks.map((stack, index) => (
              <motion.p
                variants={fadeIn("up")}
                transition={transition()}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false }}
                key={index}
                className="text-sm m-2 hover:text-lg text-center border-b hover:border-secondary p-2 sm:text-base text-textPrimary font-medium"
              >
                {stack}
              </motion.p>
            ))}
          </motion.div>
        </div>

        <motion.img
          variants={scale()}
          transition={transition()}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          src={homePageIllustation}
          alt=""
          className="max-w-full sm:max-w-[401px]"
        />
      </div>

      <div className="absolute bottom-0 left-0 w-full h-[1px] bg-divider" />
    </div>
  );
};

export default Hero;
